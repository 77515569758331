<template>
  <div>
    <Modal class="pop-package" footer-hide v-model="showPackage">
      <div class="title">Package & Plan</div>
<!--      <div class="sub-title">Please select your pricing plan</div>-->
      <div class="content">
        <div class="unlimited-content free-plan" :class="{'active-package':activePackage == 2}"
         @mouseenter="changePackage(2)">
          <div class="free-top">
            <div class="left">
              <div class="label">UNLIMITED</div>
              <div class="value">FREE</div>
              <div class="pay"></div>
              <div class="btn" @click="copyUrl()">
                <template v-if="!packageLoading">Copy URL</template>
                <template v-else>
                  <img src="@/assets/loading.gif" alt=""/>
                </template>
              </div>
            </div>
            <div class="right">
              <div class="right-title">
                Successfully invite a user and get <span>100 free queries</span>.
              </div>
              <div class="right-items">
                <div class="right-item">
                  <div class="icon">
                    <img src="@/assets/home/package-check.png" alt=""/>
                  </div>
                  <div class="item-content">
                    <span>UNLIMITED</span> companies per classification with unlimited search.
                  </div>
                </div>
                <div class="right-item">
                  <div class="icon">
                    <img src="@/assets/home/package-check.png" alt=""/>
                  </div>
                  <div class="item-content">
                    <span>FREE</span> MaxTrade
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="free-bottom" v-if="copySuccess">
            <div class="icon">
              <img src="@/assets/payment-success.png" alt=""/>
            </div>
            <div class="success-info">
              Copied. Share with the registrant.
            </div>
          </div>

        </div>
        <div class="unlimited-content" :class="{'active-package':activePackage == 1}" @mouseenter="changePackage(1)">
          <div class="left">
            <div class="label">UNLIMITED</div>
            <div class="value">$5000/y</div>
            <div class="pay">Pay Annually</div>
            <div class="btn" @click="updatePackage">
              <template v-if="!packageLoading">Select Plan</template>
              <template v-else>
                <img src="@/assets/loading.gif" alt=""/>
              </template>
            </div>
          </div>
          <div class="right">
            <div class="right-title">
              MaxData, <span>100K+</span> companies, <span>4 Million+</span> Products
            </div>
            <div class="right-items">
              <div class="right-item">
                <div class="icon">
                  <img src="@/assets/home/package-check.png" alt=""/>
                </div>
                <div class="item-content">
                  <span>UNLIMITED</span> companies per classification with unlimited search.
                </div>
              </div>
              <div class="right-item">
                <div class="icon">
                  <img src="@/assets/home/package-check.png" alt=""/>
                </div>
                <div class="item-content">
                  <span>FREE</span> MaxTrade
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="success-package" footer-hide v-model="showSuccess">
      <div class="success-package-content">
        <div class="left">
          <img src="@/assets/data-search/success.png" />
        </div>
        <div class="right">
          <div class="title">
            Submitted successfully
          </div>
          <div class="content">
            We will contact you within two working days
          </div>
        </div>
      </div>


    </Modal>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :session-id="sessionId"
      />
      <button @click="submit" class="stripe-submit" ref="stripeSubmit" style="display: none;"></button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
import { StripeCheckout } from '@vue-stripe/vue-stripe';

const {
  _package, _getStripeSessionId,_getUserInvitation
} = api;
export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  components: {
    StripeCheckout
  },
  data() {
    return {
      showPackage: false,
      showSuccess: false,
      publishableKey: 'pk_live_51NBmcYFMPa1IeCew0Vkx0zxNicg6kgcsTdODuNJS9aZ9ZXScftJ1XcNdvjcvFbR4h3uZyh1O0plqvDoCMVLUwczi00WgI4f5RE',
      loading: false,
      lineItems: [

      ],
      sessionId: '',
      successURL: 'your-success-url',
      cancelURL: 'your-cancel-url',
      packageLoading: false,
      paymentInfo: [],
      activePackage: 2,
      copySuccess: false
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {

  },
  methods: {
    copyUrl(){
      _getUserInvitation()
        .then(res => {
          let copyData = res.data.url;
          let elInput = document.createElement('input')
          elInput.value = copyData;
          document.body.appendChild(elInput)
          elInput.select()
          console.log(elInput, elInput.value)
          document.execCommand("Copy")
          elInput.remove()
          this.copySuccess = true;
        })
        .catch(({ message }) => {
          console.log(message);
        })
        .finally(() => {

        });



    },
    changePackage(num){
      this.activePackage = num;
    },
    submit () {
      this.$refs.checkoutRef.redirectToCheckout();
    },
    updatePackage(){
      this.packageLoading = true;
      this.getStripeSessionId();
    },
    getStripeSessionId(){
      _getStripeSessionId()
        .then(res => {
          this.sessionId = res.data.id;
          this.$refs.stripeSubmit.click();
        })
        .catch(({ message }) => {
          console.log(message);
        })
        .finally(() => {
          this.packageLoading = false;
        });
    },
    choose(num){
      _package({
        'package' : num
      })
        .then(response => {
          this.showSuccess = true;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
        });
    }
  }
};
</script>
<style lang="less" scoped>
.pop-package{
  .title{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 231% */

    text-align: center;
    ;

    color: #222;
    margin-top: 10px;
    text-align: left;
    margin-left: 10px;
  }
  .sub-title{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height, or 368% */

    text-align: center;
    ;

    color: #606060;
    margin: 25px auto 35px auto;

  }
  .content{
    .free-plan{
      display: block !important;
      .free-top{
        display: flex;
      }

        .free-bottom{
          margin-top: 10px;
          display: flex;
          align-items: center;
          .icon{
            margin-right: 15px;
            margin-top: 4px;
            img{
              width: 35px;
              height: 35px;
            }
          }
          .success-info{
            font-size: 16px;
            font-weight: 600;
          }

        }

    }
    .unlimited-content{
      display: flex;
      margin-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 10px;
      border: 1px solid #d0d0d0;
      padding-top: 30px;
      padding-bottom: 30px;
      opacity: 0.7;

      .left{
        margin-right: 40px;
        .label{
          font-weight: 500;
          font-size: 17px;
          /*line-height: 28px;*/
          /* or 133% */

          text-align: center;

          color: #222;

        }
        .value{
          font-weight: 600;
          font-size: 28px;
          line-height: 100%;
          /* or 57px */

          letter-spacing: -0.01em;

          color: #222;
          margin: 15px 0 10px 0;
          text-align: center;

        }
        .pay{
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          /* or 156% */

          text-align: center;

          color: #222;
        }
        .btn{
          width: 150px;
          height: 38px;

          background: #FF4835;
          border-radius: 8px;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          /* or 133% */

          text-align: center;

          /* gray / 50 */

          color: #F7FAFC;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-top: 30px;
          img{
            width: 25px;
            height: 25px;
          }
          &:hover {
            opacity: 0.9;
          }
        }
      }
      .right{
        .right-title{
          font-style: normal;


          color: #222;
          margin-bottom: 35px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          span{
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #FF4835;
          }

        }
        .right-items{
          margin-top: 40px;
          .right-item{
            display: flex;
            margin-bottom: 16px;
            .icon{
              img{
                width: 14px;
                height: 10px;
              }

              margin-right: 11px;
            }
            .item-content{
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              /* or 140% */


              color: #222;
              span{
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #FF4835;
              }

            }
          }
        }
      }
    }
    .active-package{
      opacity: 1;
      border: 3px solid #3E1DCE;
    }
    .items{
      .item-line{
        display: flex;
        margin: 10px auto;
      }
      .item{
        background: #222;
        box-shadow: 0px 4px 11px 3px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px 30px;
        height: 130px;
        margin: 5px auto;
        width: 370px;

        .item-top{
          display: flex;
          justify-content: space-between;

          .top-title{
            font-weight: 700;
            font-size: 25px;
            line-height: 27px;
            /* identical to box height, or 300% */

            text-align: center;
            ;

            color: #222;

          }
          .top-price{
            display: flex;
            .dollar{
              font-weight: 400;
              font-size: 18px;
              line-height: 18px;
              /* identical to box height, or 324% */

              text-align: center;
              /*;*/

              color: #222;
              margin-right: 3px;
            }
            .money{
              font-weight: 700;
              font-size: 25px;
              line-height: 27px;
              /* identical to box height, or 324% */

              text-align: center;
              ;

              color: #222;
            }
          }
        }
        .item-bottom{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
          .features{
            .features-item{
              display: flex;
              .icon{
                margin-right: 8px;
                img{
                  width: 18px;
                  height: 18px;

                }

              }
              .word{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                

                color: #222;

              }
            }

          }
          .btn{
            width: 105px;
            height: 35px;

            background: #FF4835;
            border-radius: 5px;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.02em;

            color: #222;
            display: none;
            justify-content: center;
            align-items: center;

          }
        }
        &:hover{
          border: 1px solid rgba(0, 0, 0, 0.25);
          .btn{
            display: flex;
            cursor: pointer;
          }
        }
      }
    }
  }

}
.success-package{
  .success-package-content{
    display: flex;
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
  }
  .left{
    img{
      width: 25px;
      height: 25px;
    }

    margin-right: 20px;
  }
  .right{
    .title{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .content{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }
  }

}
</style>

<style lang="less">
  .pop-package {

    .ivu-modal {
      width: 800px !important;
      .ivu-modal-body{
        border-radius: 15px;

        background: #efefef;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
</style>
