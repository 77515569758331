<template>
  <div class="homePage">
    <div class="banner">
      <div class="banner-top">
        <div class="top-left" >
          <div>Target <span>Customers</span>,</div>
          <div> Precision Marketing</div>
        </div>
        <div class="top-right">
          <div class="right-title">
            MaxData,<br/>
            sourcing from around the world.
          </div>
<!--          <div class="right-btn">-->
<!--            <router-link to="/maxdata">-->
<!--              <div class="btn-word">-->
<!--                Start Search-->
<!--              </div>-->
<!--            </router-link>-->

<!--            <div class="btn-icon">-->
<!--              <img src="@/assets/home/next.png" />-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="banner-bottom  animate__animated animate__slideInUp" >
        <div class="icon">
          <img src="@/assets/home/search.png" />
        </div>
        <div class="search">
          <div class="input">
            <input
              type="text"
              ref="searchInput"
              id="search-product"
              v-model="searchVal"
              @keyup.enter="search"
            />
            <!--             <label for="search-product">{{searchProductLabel}}</label>-->
          </div>
          <div class="select">
            <select v-model="selectSearchCompanyType" :class="{'end-user-company': (chooseCompanyTypeVal == 'End-user Customer') , 'non-gmo': (chooseCompanyTypeVal == 'Non GMO')}"
                    @change="chooseCompanyType($event)">
              <option v-for="type in searchCompanyType"
                      :key="type">{{type}}</option>
            </select>
          </div>
          <div class="search-btn" @click="search()">
            Search
          </div>
        </div>
        <div class="trending">
          <div class="title" @click="changeTrending()">
            <img src="@/assets/home/info.png" alt=""/>
            <span>New user? View instruction.</span>
          </div>
          <div class="items" v-if="showTrending">
            Search Brand by Product, CoPacker by industry. Using multiple names, Keywords by adding “and”, “or” in between the words.
          </div>
        </div>
      </div>
    </div>
    <div class="tags  animate__animated animate__slideInUp">
      <div class="tag-item">
        <div class="icon">
          <img src="@/assets/home/tag-1.png" />
        </div>
        <div class="word">Easy Access</div>
      </div>
      <div class="tag-item">
        <div class="icon">
          <img src="@/assets/home/tag-2.png" />
        </div>
        <div class="word">Personalized Filter</div>
      </div>
      <div class="tag-item">
        <div class="icon">
          <img src="@/assets/home/tag-3.png" />
        </div>
        <div class="word">Accurate Results</div>
      </div>
    </div>
    <div class="intro wow animate__animated animate__slideInUp">
      <div class="left">
        Connexima is a transformative platform designed for the future. <span>We are changing
        the way of trading</span>, one connection at
        a time.
      </div>
      <div class="right">
<!--        Designed to bridge and maximize the connection between buyers and sellers online around the globe, Connexima sets itself apart with its extensive ingredients database MaxData, and B2B trading community MaxTrade with accurate targeting and real time bidding and automatic response system.-->
      </div>
    </div>
    <div class="numbers wow animate__animated animate__slideInUp">
      <div class="intro-item">
        <div class='item-num'>
          <animate-number from="0" to="4"  :duration="1000" :formatter="productsFormatter"></animate-number>
        </div>
        <div class="item-line"></div>
        <div class='item-word'>Products</div>
      </div>
      <div class="intro-item">
        <div class='item-num'>
          <animate-number from="0" to="100"  :duration="1000" :formatter="companyFormatter"></animate-number>
        </div>
        <div class="item-line"></div>
        <div class='item-word'>Companies</div>
      </div>
      <div class="intro-item">
        <div class='item-num'>
          <animate-number from="0" to="100"  :duration="1000" :formatter="dailyFormatter"></animate-number>
        </div>
        <div class="item-line"></div>
        <div class='item-word'>Countries</div>
      </div>
    </div>
    <div class="industries wow animate__animated animate__slideInUp">
      <div class="title">
        <span>Industries</span> We Serve
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item item-1">
            <div class="icon">
              <img src="@/assets/home/indus-1-b.png" />
            </div>
            <div class="word">
              Nutrition
            </div>
          </div>
          <div class="item item-2">
            <div class="icon">
              <img src="@/assets/home/indus-2-b.png" />
            </div>
            <div class="word">
              Food & Beverage
            </div>
          </div>
        </div>

        <div class="line-item">
          <div class="item item-3">
            <div class="icon">
              <img src="@/assets/home/indus-3-b.png" />
            </div>
            <div class="word">
              Organics
            </div>
          </div>
          <div class="item item-4">
            <div class="icon">
              <img src="@/assets/home/indus-4-b.png" />
            </div>
            <div class="word">
              Animal Nutrition
            </div>
          </div>
        </div>

        <div class="line-item">
          <div class="item item-5">
            <div class="icon">
              <img src="@/assets/home/indus-5-b.png" />
            </div>
            <div class="word">
              Cosmetics
            </div>
          </div>
          <div class="item item-6">
            <div class="icon">
              <img src="@/assets/home/indus-6-b.png" />
            </div>
            <div class="word">
              Chemicals
            </div>
          </div>
        </div>



      </div>
    </div>
    <div class="package wow animate__animated animate__slideInUp" v-if="false">
      <div class="package-content">
        <div class="title"><span>Package</span> & Pricing</div>
        <div class="sub-title">
          MaxData, <span>100K+</span> companies, <span>4 Million+</span> Products
        </div>
        <div class="unlimited-content">
          <div class="left">
            <div class="label">UNLIMITED</div>
            <div class="value">$5000/y</div>
            <div class="pay">Pay Annually</div>

          </div>
          <div class="right">

            <div class="right-items">
              <div class="right-item">
                <div class="icon">
                  <img src="@/assets/home/package-check-3.png" alt=""/>
                </div>
                <div class="item-content">
                  <span>UNLIMITED</span> companies per classification with unlimited search.
                </div>
              </div>
              <div class="right-item">
                <div class="icon">
                  <img src="@/assets/home/package-check-3.png" alt=""/>
                </div>
                <div class="item-content">
                  <span>FREE</span> MaxTrade
                </div>
              </div>
<!--              <div class="right-item">-->
<!--                <div class="icon">-->
<!--                  <img src="@/assets/home/package-check.png" alt=""/>-->
<!--                </div>-->
<!--                <div class="item-content">-->
<!--                  24/7 support and consulting-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="btn" @click="updatePackage()">
              <template v-if="!packageLoading">Get Started</template>
              <template v-else>
                <img src="@/assets/loading.gif" alt=""/>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="solution wow animate__animated animate__slideInUp">
      <div class="solution-content">
        <div class="left">
          <div class="title">
            We Help You Get
            <div>Solutions</div>
          </div>
          <router-link to="/contact-us">
            <div class="btn">
              Contact Us
            </div>
          </router-link>

        </div>
        <div class="right">
          <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/cFvgG5Nz8jG5nxS8iGDh5t.png" />
        </div>
      </div>

    </div>

    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopRegister
      ref="popRegister"
      @successRegister="successRegister"
      @login="loginPopFunc"
    ></PopRegister>
    <PopRegisterEmail
      ref="popRegisterEmail"
      @successRegister="successRegister"
      @login="loginPopFunc"
    ></PopRegisterEmail>
    <PopRegisterSuccess ref="popRegisterSuccess"></PopRegisterSuccess>
    <completeInfo ref="completeInfo"></completeInfo>
    <PopPackage ref="popPackage"></PopPackage>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :session-id="sessionId"
        @loading="v => loading = v"
      />
      <button @click="submit" class="stripe-submit" ref="stripeSubmit" style="display: none;"></button>
    </div>
    <PaymentSuccess ref="paymentSuccess" :payment="paymentInfo"></PaymentSuccess>
  </div>
</template>

<script>
  import banner5 from "@/assets/home/banner-new-1.jpg";
  import { mapState } from "vuex";
  import "swiper/swiper-bundle.min.css";
  import Swiper from "swiper";
  import PopLogin from "@/components/popLogin";
  import PopRegister from "@/components/popRegister";
  import PopRegisterEmail from "@/components/popRegisterEmail";
  import completeInfo from "@/components/completeInfo";
  import PopRegisterSuccess from "@/components/popRegisterSuccess";
  import api from "@/api";
  import PopPackage from "@/components/packageMobile";
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import PaymentSuccess from "../components/paymentSuccess";
  const { _postSocialAuth,_getStripeSessionId,_getPaymentSuccess } = api;
  export default {
    computed: {
      ...mapState("user", ["token", "role"]),
      //上一张
      prevIndex() {
        if (this.currentIndex == 0) {
          return this.dataList.length - 1;
        } else {
          return this.currentIndex - 1;
        }
      },
      //下一张
      nextIndex() {
        if (this.currentIndex == this.dataList.length - 1) {
          return 0;
        } else {
          return this.currentIndex + 1;
        }
      }
    },
    components: {
      PopLogin,
      PopRegister,
      PopRegisterSuccess,
      completeInfo,
      PopRegisterEmail,
      PopPackage,StripeCheckout,PaymentSuccess
    },
    data() {
      return {
        mobileWidth: window.innerWidth < 800,
        dataList: [banner5],
        currentIndex: 0,
        timer: null,
        companyLogo: [
          "https://connexima.s3-us-west-1.amazonaws.com/documents/jLTeoQCca2K4sy387yBNtS.jpeg",
          "https://connexima.s3-us-west-1.amazonaws.com/documents/jLTeoQCca2K4sy387yBNtS.jpeg",
          "https://connexima.s3-us-west-1.amazonaws.com/documents/jLTeoQCca2K4sy387yBNtS.jpeg"
        ],
        // role: "" // attendee exhibitor
        activeIndex: null,
        recommendCompany: [],
        sourcingFirst: "",
        sourcingSecond: "",
        sourcingThird: "",
        searchVal: "",
        swiper: "",
        showPopLogin: false,
        showInput: true,
        searchProductLabel: 'Search Brand by Product, CoPacker by industry. Using multiple names, Keywords by adding “and”, “or” in between the words.',
        searchCompanyType: ['All Customers','Customer(CoPacker, Brands)','Organic Factory','Non GMO','Foreign Exporter','US Importer','Global Company'],
        searchCompanyTypeObj: {'All Customers' : 0,'Customer(CoPacker, Brands)':4,'Organic Factory':3,'Non GMO':7,'Foreign Exporter':2,'US Importer':1,'Global Company':6},
        selectSearchCompanyType: 'All Customers',
        chooseCompanyTypeVal: '',
        showTrending: false,
        publishableKey: 'pk_live_51NBmcYFMPa1IeCew0Vkx0zxNicg6kgcsTdODuNJS9aZ9ZXScftJ1XcNdvjcvFbR4h3uZyh1O0plqvDoCMVLUwczi00WgI4f5RE',
        loading: false,
        lineItems: [

        ],
        sessionId: '',
        successURL: 'your-success-url',
        cancelURL: 'your-cancel-url',
        packageLoading: false,
        paymentInfo: []
      };
    },

    mounted() {
      let u = this.$route.query.u;
      if (typeof u != "undefined"  && "" === this.role) {
        this.$refs.popRegisterEmail.showLogin = true;
      }
      let isLogin = this.$route.query.login;
      if (isLogin === "true" && "" === this.role) {
        this.$refs.popLogin.showLogin = true;
      }

      let socialType = this.$route.query.socialType;
      let social = this.$route.query.social;
      if (typeof social != "undefined" && typeof socialType == "undefined") {
        this.postSocial(social);
      }
      if (typeof socialType != "undefined" && socialType == "new") {
        this.$refs.completeInfo.showLogin = true;
        this.$refs.completeInfo.uStr = social;
      }
      let payment = this.$route.query.payment;
      if (payment === "true" && "" !== this.role) {
        this.getPaymentInfo();
      }
      this.$nextTick(()=>{
          this.$wow.init()
        }
      )
    },
    methods: {
      getPaymentInfo(){
        _getPaymentSuccess()
          .then(res => {
            this.paymentInfo = res.data;
          })
          .catch(({ message }) => {
            console.log(message);
          })
          .finally(() => {
            this.$refs.paymentSuccess.show = true;
          });
      },
      submit () {
        this.$refs.checkoutRef.redirectToCheckout();
      },
      updatePackage(){
        if(this.packageLoading){
          return;
        }
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
        }else{
          this.packageLoading = true;
          this.getStripeSessionId();
          // this.$refs.stripeSubmit.click();
          // this.$refs.popPackage.showPackage = true;
        }
      },
      getStripeSessionId(){
        _getStripeSessionId()
          .then(res => {
            this.sessionId = res.data.id;
            this.lineItems = res.data.price;
            this.$refs.stripeSubmit.click();
          })
          .catch(({ message }) => {
            console.log(message);
          })
          .finally(() => {
            this.packageLoading = false;
          });
      },
      changeTrending(){
        this.showTrending = !this.showTrending;
      },
      productsFormatter(num) {
        return parseInt(num) + ' Million+';

      },
      companyFormatter(num) {
        return parseInt(num) + 'K+';

      },
      dailyFormatter(num) {
        return parseInt(num)+ '+';
      },
      chooseCompanyType(e){
        let val = e.target.value;
        this.chooseCompanyTypeVal = val;
      },
      chooseTrending(product){
        let typeNum = this.searchCompanyTypeObj[this.selectSearchCompanyType];
        this.$router.push({ path: "/maxdata", query: { q: product , type:typeNum} });
      },
      onTyperComplete: function() {
        this.removeTyping();
      },
      removeTyping(){
        this.showInput = !this.showInput;
        this.$nextTick(function () {
          this.$refs.searchInput.focus();
        });
      },
      search(){
        let typeNum = this.searchCompanyTypeObj[this.selectSearchCompanyType];
        this.$router.push({ path: "/maxdata", query: { q: this.searchVal , type:typeNum} });
      },
      inputSearch(){
        if(this.searchVal != ''){
          this.searchProductLabel = '';
        }
      },
      postSocial(social) {
        _postSocialAuth({
          token: social
        })
          .then(res => {
            this.$store.dispatch("user/socialLogin", {
              ...res
            });
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {});
      },
      register() {
        this.$refs.popLogin.showLogin = false;
        this.$refs.popRegisterEmail.showLogin = true;
      },
      loginPopFunc() {
        this.$refs.popRegisterEmail.showLogin = false;
        this.$refs.popLogin.showLogin = true;
      },
      successRegister(token) {
        this.$refs.popRegisterSuccess.showLogin = true;
        this.$refs.popRegisterSuccess.token = token;
      },
      searchDataBase() {
        this.$router.push({ path: "/data-search", query: { q: this.searchVal } });
      },
      initSwiper() {
        this.swiper = new Swiper(".swiper", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 6,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          observer: true,
          observeParents: true,
          navigation: {
            nextEl: ".next",
            prevEl: ".prev"
          }
        });
      },
      prev() {
        this.swiper.slidePrev();
      },
      next() {
        this.swiper.slideNext();
      },
      gotoPage(index) {
        this.currentIndex = index;
      },
      runInv() {
        this.timer = setInterval(() => {
          this.gotoPage(this.nextIndex);
        }, 1000);
      },
      homeWithRedirect() {
        this.$router.push(`/index`);
      },
      dataSearchWithRedirect() {
        this.$router.push(`/data-search`);
      },
      productsWithRedirect() {
        this.$router.push(`/products`);
      },
      exhibitorsWithRedirect() {
        this.$router.push(`/exhibitors`);
      },
      sendInquiry() {
        if ("" === this.role) {
          this.$router.push("/user-action/login");
        } else {
          this.$router.push({
            path: "/sourcing-inquiry",
            query: {
              first: this.sourcingFirst,
              second: this.sourcingSecond,
              third: this.sourcingThird
            }
          });
        }
      },
      companyMouseOver(e) {
        this.activeIndex = e;
      },
      companyMouseLeave() {
        this.activeIndex = null;
      },
      direct(route) {
        this.$router.push("/" + route);
      }
    },
    filters: {}
  };
</script>

<style lang="less" scoped>
  @media (max-width: 1800px) and (min-width: 1700px) {
    .about {
      .top {
        height: 1020px !important;
      }
    }
  }
  @media (max-width: 1700px) and (min-width: 1600px) {
    .about {
      .top {
        height: 980px !important;
      }
    }
  }
  @media (min-width: 1600px) {
    .banner .banner-content {
      .top-title {
        font-size: 77px;
      }
      .title {
        font-size: 77px;
      }
      .sub-title {
        font-size: 33px;
      }
      .btn {
        button {
          font-size: 30px;
        }
      }
    }
    .sourcing {
      .border {
        width: 1390px;
      }
      .content {
        input {
          width: 320px;
        }
      }
    }
    .company-right {
      max-width: 863px;

      img {
        width: 863px;
        height: 575px;
      }
      video {
        max-width: 885px !important;
        height: 560px;
      }
    }
    .about {
      .top {
        height: 1100px;
      }
    }
    .about .industries .content {
      .item {
        margin-right: 60px;
        margin-left: 60px;
      }
    }
    .product .content .recommend .content .word {
      width: 750px !important;
    }
  }

  * {
    margin: 0;
    padding: 0;
  }
  ul li {
    list-style: none;
    float: left;
    width: 30px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
  .homePage {
    background: #ffffff;
    /*margin-top: 68px;*/
  }
  .navs {
    background: #ffffff;
    .content {
      display: flex;
      height: 84px;
      /*width:1500px;*/
      margin: 0 auto;
      align-items: center;
      .nav {
        margin: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #53575c;
        line-height: 17px;
        cursor: pointer;
      }
      .home {
        margin-left: 0px;
      }
      .active {
        color: #ef842d;
        cursor: auto;
      }
    }
  }

  .banner{
    margin-top: 30px;
    background: #120D21;
    /*height: 320px;*/
    padding-bottom: 30px;

    .banner-top{
      /*display: flex;*/
      /*justify-content: space-between;*/
      width: 100%;
      padding-top: 50px;
      padding-left: 20px;
      .top-left{
        padding-top: 3px;
        div{
          font-family: "Montserrat";
          font-weight: 700;
          font-size: 25px;
          line-height: 35px;
          display: flex;
          align-items: center;
          text-align: center;

          color: #FFFFFF;
          span{
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 25px;
            line-height: 35px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FF4835;
            margin-left: 9px;
          }
        }


      }
      .top-right{
        .right-title{
          font-weight: 400;
          font-size: 15px;
          line-height: 17px;
          /* or 133% */

          display: flex;
          align-items: center;

          color: hsla(0,0%,100%,.8);
          margin-bottom: 10px;
          margin-top: 20px;

        }
        .right-btn{
          width: 140px;
          height: 42px;

          background: #3E1DCE;
          border-radius: 10px;
          background: linear-gradient(to left, #3E1DCE 50%, #FF4835 50%) right;
          background-size: 210%;
          transition: .2s ease-out;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn-word{
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            /* or 143% */

            display: flex;
            align-items: center;
            text-align: center;

            color: #FFFFFF;
          }
          .btn-icon{
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 6px;
            padding-top: 0;
            img{
              width: 15px;
              height: 8px;
            }
          }
        }
        .right-btn:hover{
          background-position: left;
        }
      }
    }
    .banner-bottom{
      width: 90%;
      /*height: 204.6px;*/
      background: #FFFFFF;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 20px;
      .icon{
        position: relative;
        height: 0;
        width: 0;
        top: 38px;
        left: 35px;
        img{
          width: 17px;
          height: 19px;
        }
      }

      .search{
        /*display: flex;*/
        /*backdrop-filter: blur(2px);*/
        /* Note: backdrop-filter has minimal browser support */

        padding: 25px 20px 0 20px;
        justify-content: space-between;
        .input{
          label{
            color: #707070;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            line-height: 30px;
            white-space: nowrap;
            /*animation: moving 10s linear infinite paused;*/
          }
          input{
            width: 100%;
            height: 42px;

            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 30px;
            outline: none;
            padding-left: 40px;
            font-size: 18px;
            &:hover{
              /*border: 1px solid #FF4835;*/
            }
            &:focus{
              /*border: 1px solid #FF4835;*/
            }
            &:focus{
              ~ label{
                visibility: hidden;
              }
              /*border: 1px solid #FF4835;*/
            };
            &:not(:focus){
              ~ label{
                visibility: visible;
                animation-play-state: running
              }
            };
            &:not([value=""]){
              ~ label{
                visibility: visible;
                animation-play-state: running
              }
            }
          }
        }
        .select{
          padding: 15px 0;
          select{
            text-align-last: center;
            width: 100%;
            height: 42px;

            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 30px;
            outline: none;
            appearance:none;
            -moz-appearance:none;
            -webkit-appearance:none;
            background: url(../assets/home/select-arrow.png) no-repeat right 15px center;
            background-size: 19px 11px ;
            /*text-align: center;*/
            font-size: 14px;
            padding-right:14px;
            /*text-align: -webkit-center;*/
            /*text-indent: 90px hanging;*/
            padding-left: 10px;
            color: #000;
            &:hover{
              /*border: 2px solid #120D21;*/
            }
            &:focus{
              /*border: 2px solid #120D21;*/
            }




          }
          // .end-user-company{
          //   padding-left: 60px !important;
          // }
          // .non-gmo{
          //   padding-left: 100px !important;
          // }
          select::-ms-expand {
            display: none;
          }


        }
        .search-btn{
          width: 100%;
          height: 38px;

          background: #FF4835;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          /* or 133% */

          display: flex;
          align-items: center;
          text-align: center;

          color: #FFFFFF;
          cursor: pointer;

        }
      }
      .trending{
        padding: 16px 0 10px 20px;
        /*display: flex;*/
        .title{
          margin-right: 7px;
          position: relative;
          /*top: 3px;*/
          span{
            position: relative;
            left: 7px;
            bottom: 2.5px;
            font-size: 14px;

          }
          img{
            width: 15px;
            height: 15px;
          }
        }
        .items{
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;

          color: #979797;
          .item{
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            display: flex;
            align-items: center;

            color: #595959;
            cursor: pointer;
            margin-left: 8px;

          }
        }

      }
    }
  }
  .tags{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px auto;
    padding: 0 20px;
    .tag-item{
      display: flex;
      .icon{
        padding-top: 5px;
        img{
          width: 20px;
          height: 20px;
        }
      }
      .word{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #363636;
        margin-left: 6px;

      }
    }
  }
  .intro{
    /*display: flex;*/
    justify-content: space-between;
    width: 100%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 20px;
    .left{
      width: 100%;
      font-style: normal;
      font-family: "Montserrat";
        font-weight: 600;
      font-size: 18px;
      line-height: 22px;


      color: #000000;
      span{
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        /* or 140% */

        color: #FF4835;
      }
    }
    .right{
      margin-top: 20px;
      width: 100%;

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      /* or 129% */

      /*display: flex;*/
      /*align-items: center;*/

      color: #000000;

    }
  }
  .intro-img{
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      height: 220px;
      /*height: 750px;*/
    }
  }
  .company {
    text-align: center;
    margin-top: 40px;
    .top {
      margin-bottom: 60px;
      margin-left: 34px;
      .top-title {
        font-size: 48px;
        font-weight: 700;
        color: #252729;
      }
      .top-desc {
        font-size: 24px;
        font-weight: 400;
        color: #252729;
      }
    }
    .recommend {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .company-left {
        .logos {
          display: flex;
          max-width: 615px;
          flex-flow: row wrap;
          align-content: flex-start;
          min-width: 600px;
          .logo {
            height: 191px;
            width: 191px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              /*height: 191px;*/
              width: 139px;
            }
          }
          .active {
            background: #f2f2f2;
          }
        }
      }
      .company-right {
        max-width: 863px;
        img {
          /*width:863px;*/
          /*height:575px*/
        }
      }
    }
    .all-company {
      margin: 60px 0 100px 0;
      button {
        cursor: pointer;
        font-size: 24px;
        border: 1px solid #ef7f21;
        font-weight: bold;
        height: 63px;
        width: 400px;
        background-color: white;
        border-radius: 5px;
        font-weight: 700;
        color: #ef7f21;
        div {
          display: inline-block;
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .industries {
    margin: 40px auto 0 auto;
    width: 100%;
    .line-item{
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      margin-top: 20px;
    }
    .title{
      font-family: "Montserrat";
        font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #000;
      margin-bottom: 30px;
      padding-left: 20px;
      span{
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #FF4835;
        margin-right: 10px;
      }
    }
    .content{
      /*display: flex;*/
      /*justify-content: space-between;*/
      width: 100%;
      .item{
        width: 47%;
        height: 130px;
        border-radius: 10px;
        .word{
          font-family: "Montserrat";
        font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* or 132% */

          display: flex;
          justify-content: center;

          color: #000000;
          margin-bottom: 8px;

        }
        .icon{
          text-align: center;
          margin-top: 40px;
          img{
            width: 40px;
            height: 40px;
          }

        }
      }
      .item:hover{
        .icon{
          filter: invert(100%);
        }
        .word{
          filter: invert(100%);
        }
      }
      .item-1{
        background-image: linear-gradient(130deg,#c3caf6,#ede0f9);
        transition: background-color .2s;
      }
      .item-1:hover{
        background-image: linear-gradient(130deg,#3f55dd,#9d4ee5);
      }
      .item-2{
        background-image: linear-gradient(130deg,#e1f8ff,#cdffe4)
      }
      .item-2:hover{
        background-image: linear-gradient(130deg,#55b8d6,#47e48f)
      }
      .item-3{
        background-image: linear-gradient(130deg,#f7eefe,#fae9ec)
      }
      .item-3:hover{
        background-image: linear-gradient(130deg,#ca86ff,#ff6580)
      }
      .item-4{
        background-image: linear-gradient(130deg,#e0fbfe,#ebf0f2)
      }
      .item-4:hover{
        background-image: linear-gradient(130deg,#6df0ff,#00a1e2)
      }
      .item-5{
        background-image: linear-gradient(130deg,#edf8ed,#e7f8c3)
      }
      .item-5:hover{
        background-image: linear-gradient(130deg,#089499,#00ff84)
      }
      .item-6{
        background-image:linear-gradient(130deg,#fafafa,#fef4f5 72%)
      }
      .item-6:hover{
        background-image:linear-gradient(130deg,#0c5bfa,#f66e7c 72%)
      }
    }
  }
  .solution{
    background: #F9F8F8;
    margin-top: 80px;
    width: 100%;
    margin-bottom: 50px;
    .solution-content{
      width: 100%;
      margin: 0 auto;
      /*display: flex;*/
      /*justify-content: space-between;*/
    }
    .left{
      padding: 50px 0 0 20px;
      .title{
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        /*display: flex;*/
        /*align-items: center;*/

        color: #000000;
        div{
          font-family: "Montserrat";
        font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #FF4835;
          margin-top: 6px;
        }
      }
      .btn{
        width: 120px;
        height: 40px;

        background: #FF4835;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        /* or 111% */

        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        color: #FFFFFF;
        cursor: pointer;
      }
    }
    .right{
      display: flex;
      align-items: center;
      margin-top: 50px;
      img{
        width: 100%;
        /*height: 460px;*/
      }
    }
  }
  .product {
    /*width:1500px;*/
    /*height:900px;*/
    background: #2b2c3f;
    margin: 0 auto 0 auto;
    padding-bottom: 50px;
    .content {
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      .pic {
        /*width:50%;*/
        margin-top: 70px;
        img {
          height: 418px;
          width: 513px;
        }
      }
      .recommend {
        /*margin-top:150px;*/
        /*margin-left:100px;*/
        .title {
          /*width: 460px;*/
          /*height: 134px;*/
          font-size: 30px;
          line-height: 37px;

          color: #ffffff;
          margin-top: 62px;
        }
        .content {
          .word {
            width: 580px;
            .light {
              font-size: 16px;
              color: #ffffff;
              margin-top: 32px;
            }
            .strong {
              font-weight: 500;
              font-size: 18px;
              line-height: 30px;

              color: #ffffff;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .browse {
      width: 186px;
      height: 50px;
      background: #f57f45;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin-top: 67px;
    }
  }
  .storefronts {
    margin: 0 auto;
    text-align: center;
    .top {
      /*display: flex;*/
      justify-content: center;
      margin: 58px 0 25px 0;
      text-align: left;
      .right {
        margin-left: 24px;
      }
      .left {
        margin-right: 24px;
      }
      .word {
        font-weight: 600;
        font-size: 30px;
        line-height: 37px;
        color: #222222;
      }
    }
    .title {
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 50px;

      color: #222222;
      text-align: left;
    }
    .content {
      .swiper {
        .swiper-slide {
          width: 241.667px;
          margin-right: 10px;
        }
      }

      .recommend {
        width: 150px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
    .btn {
      margin: 10px auto 72px auto;
      width: 205px;
      height: 50px;

      background: #f57f45;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .prev {
    position: relative;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    color: #252729;
    width: 20px;
    height: 38px;
    left: -50px;
    top: -80px;
  }
  .next {
    position: relative;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    color: #252729;
    width: 20px;
    height: 38px;
    left: 1450px;
    top: -125px;
  }
  .we {
    margin: 0 auto;
    .title {
      font-weight: 600;
      font-size: 30px;
      line-height: 48px;
      /* identical to box height, or 120% */

      letter-spacing: -0.03em;

      color: #222;
      margin: 50px auto -4px auto;
    }
    .content {
      display: flex;
      justify-content: left;
      height: 420px;
      margin-top: 30px;
      .left {
        img {
          width: 649px;
          height: 378px;
        }
        .img-bottom {
          position: relative;
          bottom: 97px;
          svg{
            width: 649px;
          }
        }
        .img-right {
          position: relative;
          bottom: 373px;
          left: 609px;
          svg{
            width: 70px;
            height: 70px;
          }
        }

        margin-right: 120px;
      }
      .right {
        position: relative;
        width: 600px;
        svg{
          width: 37px;
          height: 37px;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
          margin: 0 auto 0 auto;
        }
        .word {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          /* or 150% */

          /*letter-spacing: -0.02em;*/

          color: #757095;
        }
      }
    }
  }
  .package{
    /*height: 700px;*/

    background: #120D21;
    margin-top: 50px;
    padding-bottom: 30px;
    .package-content{
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      .sub-title{
        font-style: normal;


        color: #FFFFFF;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        span{
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: #FF4835;
        }
      }
      .unlimited-content{
        /*display: flex;*/
        margin-top: 30px;
        .left{
          /*margin-right: 115px;*/
          margin-bottom: 20px;
          .label{
            font-weight: 800;
            font-size: 16px;
            line-height: 20px;
            /* or 133% */

            text-align: center;

            color: #FFFFFF;

          }
          .value{
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 30px;
            line-height: 100%;
            /* or 57px */

            letter-spacing: -0.01em;

            color: #FFFFFF;
            margin: 18px 0 10px 0;
            text-align: center;

          }
          .pay{
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* or 156% */

            text-align: center;

            color: #FFFFFF;
          }

        }
        .right{
          .right-title{


          }
          .right-items{
            .right-item{
              display: flex;
              margin-bottom: 20px;
              .icon{
                position: relative;
                top: 1px;
                img{
                  width: 16px;
                  height: 16px;
                }

                margin-right: 10px;
              }
              .item-content{
                font-weight: 600;
                font-size: 15px;
                line-height:20px;
                /* or 140% */


                color: #fff;
                span{
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 20px;
                  color: #FF4835;
                }

              }
            }
          }
          .btn{
            width: 130px;
            height: 38px;

            background: #FF4835;
            border-radius: 8px;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* or 133% */

            text-align: center;

            /* gray / 50 */

            color: #F7FAFC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 20px auto 10px auto;
            img{
              width: 20px;
              height: 20px;
            }
          }
        }
      }

    }
    .title{
      font-family: "Montserrat";
        font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;

      color: #fff;
      span{
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #FF4835;
        margin-right: 8px;

      }

      padding-top: 30px;

    }
    .sub-title{
      div{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
        span{
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          color: #FF4835;
          margin-right: 6px;

        }
        .million{
          margin-left: 5px;
        }
      }
      margin: 20px 0 35px 0;
    }
    .content{
      .price-line{
        width: 100%;
        height: 0;
        border: 0.0625rem solid #fff;
        margin: 30px auto;
      }
      .price{
        width: 100%;
        .top{
          display: flex;
          justify-content: space-between;
          .item{
            .item-level{
              font-weight: 600;
              font-size: 17px;
              line-height: 19px;
              display: flex;
              align-items: center;

              color: #FFFFFF;
              margin-bottom: 18px;

            }
            .item-price{
              display: flex;
              .dolar{
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;

                color: #FFFFFF;
                margin-right: 3px;

              }
              .money{
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                display: flex;
                align-items: center;

                color: #FFFFFF;

              }

              margin-bottom: 10px;
            }
            .item-per{
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: center;

              color: #FFFFFF;

            }
          }
          .item-annual{
            display: flex;
            height: 22px;
            position: relative;
            top: 30px;
            .icon{
              img{
                width: 21px;
                height: 21px;
                margin-right: 10px;
              }
            }
            .word{
              font-weight: 600;
              font-size: 12px;
              line-height: 17px;
              display: flex;
              align-items: center;

              color: #FFFFFF;

            }
          }
        }
      }
      .fetures{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
        margin-top: 20px;

      }
      .maxdata{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        .item-title{
          font-weight: 600;
          font-size: 17px !important;
          line-height: 22px !important;
          display: flex;
          align-items: center;

          color: #FFFFFF;
          width: 99px;
          align-items: flex-start !important;

        }
        .item{
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;

          color: #fff;
          flex-direction: column;
          justify-content: center;
          height: 30px;
          .item-num{
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #FF4835;
            width: 70px;
          }
          .item-company{
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #fff;
          }
        }
        .item-1{
          width: 55px;
          position: relative;
          /*left: 30px;*/
        }
        .item-2{
          width: 90px;
          position: relative;
          /*left: 10px;*/
        }
        .item-3{
          width: 95px;
          position: relative;
          right: 10px;
        }
        .item-4{
          width: 60px;
          position: relative;
          /*left: 20px;*/
        }
        .item-5{
          width: 90px;
          position: relative;
          /*left: 3px;*/
        }
        .item-6{
          width: 105px;
          position: relative;
          /*right: 15px;*/
        }
      }
      .line{
        width: 100%;
        height: 0px;

        border: 1px solid #4e4e4e;
        margin: 25px auto;
      }
      .maxtrade{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .item-title{
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          display: flex;
          align-items: center;

          color: #FFFFFF;
        }
        .item{
          img{
            width: 20px;
            height: 15px;
            position: relative;
            right: 60px;
          }
        }
        .item-1{
          position: relative;
          left: 8px;
        }
        .item-2{
          position: relative;
          right: 0px;
        }
        .item-3{
          position: relative;
          left: 10px;
        }
        .item-4{
          position: relative;
          left: 15px;
          /*left: 30px;*/
        }
        .item-5{
          position: relative;
          /*left: 10px;*/
        }
        .item-6{
          position: relative;
          /*right: 10px;*/
        }
      }
    }
  }
  .tedst {
    margin-top: 65px;
    .title {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      color: #222222;
      text-align: center;
    }
    .sub-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #222222;
      text-align: center;
      margin: 20px auto 40px auto;
      max-width: 900px;
    }
    .items {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        background: #fff;
        border-radius: 10px;
        width: 470px;
        border: 1px solid #dfdfdf;
        padding: 20px;
        /*margin: 0 30px 0 30px;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 140px;
        .item-content {
          color: #1b1c31;
          font-size: 16px;
          font-weight: 600;
        }
        .item-more {
          color: #f57f45;
          font-weight: 600;
        }
      }
    }
    .search {
      background: #f57f45;
      border-radius: 10px;
      width: 186px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin: 50px auto 0 auto;
    }
  }
  .tedst {
    display: flex;
    margin: 80px auto 120px auto;
    justify-content: space-between;
    .left {
      display: flex;
      .part1 {
        width: 342px;
        height: 388px;
        border-radius: 22px;
        border: 1px solid #dfdfdf;
        padding: 20px;
        .top {
          display: flex;
          height: 60px;
          .title {
            font-size: 22px;
            font-weight: 600;
          }
          .top-logo {
            position: relative;
            left: 80px;
            bottom: 50px;
          }
        }
        .companies {
          .item {
            display: flex;
            margin-bottom: 15px;
            .logo {
              img {
                width: 50px;
                height: 50px;
              }
            }
            .content {
              position: relative;
              top: 2px;
              left: 15px;
              .top-content {
                font-weight: 600;
                font-size: 18px;
              }
              .bottom-content {
                font-size: 12px;
                color: #6c6c6c;
                font-weight: 600;
              }
            }
          }
        }
        .more {
          color: #ff6160;
          font-size: 16px;
          font-weight: 600;
          margin-top: 55px;
        }
      }
      .part-2 {
        width: 342px;
        height: 252px;
        border: 1px solid #dfdfdf;
        padding: 20px;
        border-radius: 22px;
        top: 180px;
        position: relative;
        right: 140px;
        z-index: 99;
        background: #fff;
        .title {
          text-align: center;
          font-weight: 600;
          font-size: 22px;
        }
        .input {
          display: flex;
          margin: 35px auto 40px auto;
          .input-val {
            input {
              width: 250px;
              height: 50px;
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
              border: 1px solid #cacaca;
              color: #cacaca;
              padding-left: 5px;
              outline: none;
            }
          }
          .input-search {
            background: #fdbc64;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            width: 50px;
            height: 50px;
          }
        }
        .learn-more {
          width: 215px;
          height: 42px;
          background: #f57f45;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          border-radius: 10px;
        }
      }
    }
    .right {
      position: relative;
      right: 50px;

      .title {
        font-weight: 600;
        font-size: 30px;
        color: #222;
      }
      .sub-title {
        font-size: 18px;
        color: #64607d;
        margin: 30px 0 30px 0;
      }
      .right-content {
        .item {
          display: flex;
          margin-bottom: 20px;
          .word {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .top .input{
    background: #fff;
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
    height: 60px;
    label{
      color: #707070;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 60px;
      white-space: nowrap;
      animation: moving 10s linear infinite paused;
    }
    input{
      background: transparent;
      /*width: 100%;*/
      border: none;
      outline: none;
      height: 100%;
      margin-bottom: 10px;
      padding: 0 .5rem;
      position: relative;
      z-index: 1;
      &:focus{
        ~ label{
          visibility: hidden;
        }
      };
      &:not(:focus){
        ~ label{
          visibility: visible;
          animation-play-state: running
        }
      };
      &:not([value=""]){
        ~ label{
          visibility: visible;
          animation-play-state: running
        }
      }
    }
  }
  @keyframes moving{
    0% {  transform: translateX(10%)}
    100% {  transform: translateX(-40%)}
  }
  .auto-typing{
    width: 670px;
    background: #f2f2f2;
    height: 60px;
    padding-left: 10px;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
  }
  .numbers{
    /*display: flex;*/
    margin: 0 auto;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    /*width: 1400px;*/
    .intro-item{
      border-radius: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 280px;
      height: 240px;
      box-shadow: 0 0 10px 0 rgba(0,66,128,.2);
      margin: 20px auto;
      .item-num{
        span{
          color: #FF4835;
          font-size: 30px;
          line-height: 1.8;
          font-family: "Montserrat";
        font-weight: 600;
          letter-spacing: -1.3px;
          display: flex;
          justify-content: center;
        }

      }
      .item-line{
        width: 100px;
        height: 0px;
        border: 1px solid #ededed;
        margin: 8px auto 8px auto;
      }
      .item-word{
        display: flex;
        justify-content: center;
        font-size: 22px;
        /*margin-top: 30px;*/
        font-family: "Montserrat";
        font-weight: 600;
      }
    }
  }
</style>
